
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoApp from '@/servicos/ServicoApp';
import { IResposta } from '@/core/models/IResposta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IIntegracao } from '@/models/Entidades/IIntegracao';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';
import storeApp from '@/store/storeApp';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import SelecionarSimNao from '@/core/components/UI/SelecionarSimNao.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';

export default defineComponent({
  name: 'Erp',
  components: {
    ComunicacaoApi,
    SelecionarSimNao,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();
    const servicoApp = new ServicoApp();
    const state = reactive({
      integracoes: [] as IIntegracao[],
    });

    function limparDados() {
      state.integracoes = [];
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.integracoes = await servicoApp.obterIntegracoesErp();
      appBase.carregando = false;
    });

    async function salvar(integracao: IIntegracao) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as suas informações.');
      appBase.resposta = await servicoApp.atualizarIntegracao(integracao);
      apresentarResposta();
    }

    async function sincronizar(integracao: IIntegracao) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos sincronizando as suas informações.');
      appBase.resposta = await servicoApp.sincronizarInformacoes(integracao.codigo);
      apresentarResposta();
    }

    async function testarComunicacao(integracao: IIntegracao) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor...');
      appBase.resposta = await servicoApp.testarComunicacao(integracao);
      apresentarResposta();
    }

    function apresentaDataSincronizacao(data:string): string {
      if (UtilitarioGeral.valorValido(data)) {
        const dataDayJs = UtilitarioData.converterDataParaDayJs(data);
        return UtilitarioData.converterDataHoraMinutoSegundoJson(dataDayJs);
      }
      return '';
    }

    return {
      appBase,
      state,
      salvar,
      UtilitarioGeral,
      UtilitarioData,
      ETipoIntegracao,
      ETipoDado,
      sincronizar,
      testarComunicacao,
      storeApp,
      apresentaDataSincronizacao,
    };
  },
});
